import { t } from 'i18next'
import type { Component } from 'solid-js'
import { type AdListingAttribute } from '../../features/product/domain/models/ad'
import { type Position } from '../../shared/helpers/dom.functions'
import { useNumberFormatter } from '../../shared/helpers/currency-formatter'

export interface TotalSalesTooltipProps {
  readonly breakdown: AdListingAttribute
  readonly position?: Position
}

const TotalSalesTooltip: Component<TotalSalesTooltipProps> = (props) => {
  const { currencyFormatter } = useNumberFormatter()
  return (
    <div
      class="flex flex-col border-[1px] border-gray-200 gap-2 w-[332px] text-sm text-gray-900 absolute bg-white shadow-lg rounded-lg z-30 p-4"
      style={{
        left: `${props.position?.left ?? 0}px`,
        top: `${props.position?.top ?? 24}px`
      }}>
      <span class="font-bold">{t('ls_breakdown_breakdown')}</span>
      <div class="flex justify-between">
        <div class="flex gap-2 items-center">
          <div class="w-2 h-2 bg-lime-600 rounded-full" />
          {t('ls_ads_sales_breakdown_1')}
        </div>
        { currencyFormatter(props.breakdown.fromListing) }
      </div>
      <div class="flex justify-between">
        <div class="flex gap-2 items-center">
          <div class="w-2 h-2 bg-pink-500 rounded-full"/>
          {t('ls_ads_sales_breakdown_2')}
        </div>
        {currencyFormatter(props.breakdown.fromOtherListings)}
      </div>
      <div class="flex justify-between">
        <div class="flex gap-2 items-center">
          <div class="w-2 h-2 bg-purple-500 rounded-full"/>
          {t('ls_ads_sales_breakdown_3')}
        </div>
        {currencyFormatter(props.breakdown.organic)}
      </div>
      <div class="h-px bg-gray-200 w-full"/>
      <div class="flex justify-between font-bold">
        <div class="flex gap-2 items-center">
          <div class="w-2 h-2 bg-orange-500 rounded-full"/>
          {t('ls_ads_sales_breakdown_totals')}
        </div>
        {currencyFormatter(props.breakdown.total)}
      </div>
    </div>
  )
}

export default TotalSalesTooltip

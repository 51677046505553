import { type Component } from 'solid-js'
import { t } from 'i18next'
import MiniChevronRight from '../../assets/heroicons/MiniChevronRight'
import QuestionMarkCircle from '../../assets/heroicons/QuestionMarkCircle'

const KnowledgeBaseLink: Component = () => {
  return (
    <a target="_blank" href="https://support.profittree.io">
      <div class="rounded-lg flex flex-col gap-2 w-full 2xl:w-full max-w-[572px] bg-white overflow-hidden h-fit shadow p-4">
        <div class="flex justify-between items-center">
          <div class="flex items-center gap-2 text-lg font-semibold">
            <span class="text-japanese-500"><QuestionMarkCircle size={7} /></span>
            {t('ls_education_knowledge_base_title')}
          </div>
          <span class="text-gray-500"><MiniChevronRight /></span>
        </div>
        <span class="text-sm">{ t('ls_education_knowledge_base_description') }</span>
      </div>
    </a>
  )
}

export default KnowledgeBaseLink

import { type Component } from 'solid-js'
import { t } from 'i18next'
import { A } from '@solidjs/router'
import CreditCard from '../../assets/heroicons/CreditCard'

const YourPaymentsSection: Component = () => {
  return (
    <>
      <div class="h-px w-full bg-gray-200"/>
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <span class="text-sm font-bold">{t('ls_subscription_payments_title')}</span>
          <span class="text-xs text-gray-500">{t('ls_subscription_payments_description')}</span>
        </div>
        <A href="https://customer-portal.paddle.com/cpl_01hpr97w7gayyzbwgnp3eyrcxg" class="btn btn--white">
          <CreditCard size={5}/>{t('ls_subscription_payments_btn')}
        </A>
      </div>
    </>
  )
}

export default YourPaymentsSection

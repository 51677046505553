import { type Component, createSignal, type JSX, Show } from 'solid-js'
import { A } from '@solidjs/router'
import { Trans } from '@mbarzda/solid-i18next'
import { useShop } from '../../shared/providers/shop.provider'
import { FetchStatus } from '../../features/shop/domain/models/shop'
import MiniInformationCircle from '../../assets/heroicons/MiniInformationCircle'
import { t } from 'i18next'

export interface SidebarItemProps {
  readonly url: string
  readonly icon: JSX.Element
  readonly textKey: string
  readonly onItemSelected: () => void
  readonly onlyFullFetch?: true
}

const SidebarItem: Component<SidebarItemProps> = (props) => {
  const { selectedShop } = useShop()
  const isLinkActive = !props.onlyFullFetch || selectedShop()?.fullFetch === FetchStatus.Completed
  const [isTooltipShown, setIsTooltipShown] = createSignal(false)

  return (
    <>
      <Show when={isLinkActive}>
        <A onClick={props.onItemSelected} href={props.url} activeClass="bg-japanese-800 !text-june_bud-500" classList={{ 'opacity-50': !isLinkActive }} class="text-forest_green-200 hover:text-june_bud-500 hover:bg-japanese-800 flex items-center rounded-md p-2 text-sm leading-6 font-medium gap-2">
          {props.icon}
          <span class="text-white"><Trans key={`ls_sidebar_${props.textKey}`} /></span>
        </A>
      </Show>
      <Show when={!isLinkActive}>
        <div class="relative">
          <div class="flex items-center rounded-md p-2 text-sm leading-6 font-medium justify-between gap-2"
               onMouseLeave={() => setIsTooltipShown(false)}
               onMouseEnter={() => setIsTooltipShown(true)}
          >
            <div class="flex gap-2 opacity-60">
              <span class="text-forest_green-200">{props.icon}</span>
              <span class="text-white"><Trans key={`ls_sidebar_${props.textKey}`}/></span>
            </div>
            <span class="text-gray-400 opacity-60"><MiniInformationCircle/></span>
          </div>
          <Show when={isTooltipShown()}>
            <div class="absolute z-50 top-[40px] left-2 max-w-[196px] p-3 bg-white text-gray-900 shadow-lg rounded-md text-xs">
              { t('ls_sidebar_blocked') }
            </div>
          </Show>
        </div>
      </Show>
    </>
  )
}

export default SidebarItem
